* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.cometchat-sticker-keyboard {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px;
  flex-direction: column;
  gap: 0px;
  border-radius: var(--cometchat-radius-4, 16px);
  border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
  background: var(--cometchat-background-color-01, #FFF);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-sticker-keyboard__tabs {
  min-height: 40px;
  height: 40px;
  display: flex;
  gap: var(--cometchat-padding-2, 8px);
  border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);
  overflow-x: auto;
  overflow-y: hidden;
    /* Force hardware acceleration */
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    will-change: scroll-position; /* Hint the browser for performance optimization */
}


.cometchat-sticker-keyboard__tab{
  padding: var(--cometchat-padding-1,4px);
}
.cometchat-sticker-keyboard__tab-active{
  border-bottom: 2px solid var(--cometchat-primary-color, #6852D6);
}


.cometchat-sticker-keyboard__tab > img {
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-3, 12px);
  border-radius: var(--cometchat-radius-max, 800px);
  cursor: pointer;
}

.cometchat-sticker-keyboard__tab-active > img {
  background: var(--cometchat-extended-primary-color-100, #EDEAFA);
}

.cometchat-sticker-keyboard__list {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: wrap;
  background: inherit;
  padding: var(--cometchat-padding-3) var(--cometchat-padding-3) 0px var(--cometchat-padding-3);
}


.cometchat-sticker-keyboard__list-item {
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-2, 8px);
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.cometchat-sticker-keyboard__tabs::-webkit-scrollbar {
  background: transparent;
  height: 0px;
  width: 0px;
}

.cometchat-sticker-keyboard__tabs::-webkit-scrollbar-thumb {
  background: rgb(232, 229, 229);
  border-radius: 8px;
}

.cometchat-sticker-keyboard__list::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

.cometchat-sticker-keyboard__list::-webkit-scrollbar-thumb {
  background: rgb(232, 229, 229);
  border-radius: 8px;
}



.cometchat-sticker-keyboard__shimmer-tabs {
  display: flex;
  padding: var(--cometchat-padding-2, 8px);
  align-items: center;
  gap: var(--cometchat-padding-2, 8px);
  border-bottom: 1px solid var(--cometchat-border-color-default, #E8E8E8);

}

.cometchat-sticker-keyboard__shimmer-tab {
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--cometchat-radius-max, 800);
  background: var(--cometchat-shimmer-gradient-color, linear-gradient(90deg, #E0E0E0 0%, #EEE 100%));
  animation: shimmerAnimation 10s infinite linear;
}

.cometchat-sticker-keyboard__shimmer-list {
  gap: var(--cometchat-padding-3,12px);
}


.cometchat-sticker-keyboard__shimmer-list-item {
  background: var(--cometchat-shimmer-gradient-color, linear-gradient(90deg, #E0E0E0 0%, #EEE 100%));
  animation: shimmerAnimation 5s infinite linear;
  border-radius: 8px;
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;


}

.cometchat-sticker-keyboard__empty-view {
  display: flex;
  height: 272px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-3, 12px);
  flex-shrink: 0;
  align-self: stretch;
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  font: var(--cometchat-font-heading4-bold);
  font-style: normal;
}

.cometchat-sticker-keyboard__error-view {
  display: flex;
  height: 272px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
}

.cometchat-sticker-keyboard__empty-view-icon {
  width: 60px;
  height: 60px;
  -webkit-mask-image: url("../../assets/sticker.svg");
  mask-image: url("../../assets/sticker.svg");
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  background-color: var(--cometchat-icon-color-tertiary, #DCDCDC);
}




@keyframes shimmerAnimation {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}