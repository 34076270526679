/* Error state view container */
.cometchat-message-list__error-state-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding-5, 20px);
    justify-content: center;
    align-items: center;
}

/* Icon in the error state view */
.cometchat-message-list__error-state-view-icon {
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    border: none;
    align-self: center;
}
.cometchat-message-list .cometchat-message-list__footer-smart-replies,.cometchat-message-list__footer-conversation-starter{
    width: 100%;
}
/* Body of the error state view */
.cometchat-message-list__error-state-view-body {
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding, 2px);
}

/* Title in the error state view body */
.cometchat-message-list__error-state-view-body-title {
    width: 280px;
    color: var(--cometchat-text-color-primary);
    text-align: center;
    font: var(--cometchat-font-heading3-bold);
    font-style: normal;
}

/* Description in the error state view body */
.cometchat-message-list__error-state-view-body-description {
    width: 280px;
    color: var(--cometchat-text-color-secondary);
    text-align: center;
    font: var(--cometchat-font-body-regular);
    font-style: normal;
}



/* Thread view replies inside message bubble */
.cometchat-message-bubble__thread-view-replies {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Date inside outgoing message bubble */
.cometchat-message-bubble-outgoing .cometchat-message-bubble__status-info-view .cometchat-date {
    color: var(--cometchat-static-white);
    font: var(--cometchat-font-caption2-regular);
}

/* Date inside incoming message bubble */
.cometchat-message-bubble-incoming .cometchat-message-bubble__status-info-view .cometchat-date {
    color: var(--cometchat-neutral-color-600);
    font: var(--cometchat-font-caption2-regular);
}

/* Text bubble inside message bubble */
.cometchat-message-bubble .cometchat-text-bubble {
    padding-bottom: 0px;
}

/* Text bubble inside message bubble */
.cometchat-message-bubble .cometchat-delete-bubble {
    padding-bottom: 0px;
}

/* Read more/less inside text bubble */
.cometchat-message-bubble .cometchat-text-bubble .cometchat-text-bubble__read-more,
.cometchat-text-bubble__read-less {
    padding-bottom: 0px;
    padding-right: 0px;
}

/* Status info for whiteboard message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__whiteboard-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
}

/* Status info for delete message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__delete-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
    padding-right: var(--cometchat-padding-2);
}

/* Status info for document message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__document-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
}

/* Status info for whiteboard message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__whiteboad-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
}

/* Status info for poll message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__pol-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
}

/* Status info for audio message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__audio-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
}

/* Status info for file message inside message bubble */
.cometchat-message-bubble .cometchat-message-bubble__file-message .cometchat-message-bubble__status-info-view {
    padding-top: 0px;
}

/* Status info view inside message bubble */
.cometchat-message-bubble__status-info-view {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    width: fit-content;
    padding: var(--cometchat-padding-1, 4px);
    gap: var(--cometchat-padding-1, 4px);
    border-radius: var(--cometchat-radius-max, 1000px);
}

.cometchat-message-bubble__audio-call .cometchat-call-bubble {
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-2, 8px) var(--cometchat-padding-3, 12px) var(--cometchat-padding-2, 8px);
    ;
}
.cometchat-fullscreen-viewer .cometchat-fullscreen-viewer__body-image{
   max-width: 100%;
   max-height: 84vh;
   object-fit: contain;
}

.cometchat-message-bubble__audio-call .cometchat-call-bubble .cometchat-call-bubble__body {
    padding: 0;
}
.cometchat-message-bubble__thread-view-replies .cometchat-button{
    align-items: flex-end;
    
}
.cometchat-reactions-info-wrapper > .cometchat{
    width:fit-content;
}
.cometchat-message-bubble__thread-view-replies .cometchat-button .cometchat-button__text{
    margin-bottom: -2px;
}
.cometchat-message-bubble__body-footer-view .cometchat-reactions  .cometchat{
    width: fit-content;
}
.cometchat-reactions  .cometchat-popover,.cometchat-reactions  .cometchat-popover__button{
width: fit-content;
}
/* Date header inside message list */
.cometchat-message-list__date-header {
    position: absolute;
    top: 12px;
    z-index: 1;
}
.cometchat-message-list__bubble-date-header{
    margin: auto;
    width: fit-content;
}
.cometchat-message-list__bubble-date-header,
.cometchat-message-list__date-header {
    display: flex;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-2, 8px);
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-1, 4px);
    border: 1px solid var(--cometchat-border-color-dark);
    background: var(--cometchat-background-color-02);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* Helper text for status info view */
.cometchat-message-bubble__status-info-view-helper-text {
    text-align: right;
    color: var(--cometchat-static-white);
    font: var(--cometchat-font-caption2-regular);
    align-self: flex-end;
    padding-bottom: 1.5px;
}

/* Button inside thread view replies */
.cometchat-message-bubble__thread-view-replies .cometchat-button {
    background: transparent;
    gap: var(--cometchat-padding-1, 4px);
    justify-content: flex-start;
    padding: 0;
    height: 100%;
    width: fit-content;
}

/* Thread view replies layout */
.cometchat-message-bubble__thread-view-replies {
    display: flex;
    height: 24px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-1, 4px) 0px var(--cometchat-padding-1, 4px);
    align-items: center;
    align-self: stretch;
}

/* Outgoing thread view replies */
.cometchat-message-bubble-outgoing .cometchat-message-bubble__thread-view-replies {
    justify-content: flex-end;
}

/* Text inside thread view replies button */
.cometchat-message-bubble__thread-view-replies .cometchat-button__text {
    color: var(--cometchat-text-color-primary);
    text-align: right;
    font: var(--cometchat-font-caption1-regular);
}

/* Icon inside thread view replies button */
.cometchat-message-bubble__thread-view-replies .cometchat-button__icon {
    height: 16px;
    background: var(--cometchat-icon-color-secondary);
    width: 16px;
    -webkit-mask-size: contain;
}

/* Helper text for incoming message bubble status info */
.cometchat-message-bubble-incoming .cometchat-message-bubble__status-info-view-helper-text {
    text-align: right;
    color: var(--cometchat-neutral-color-600);
    font: var(--cometchat-font-caption2-regular);
    align-self: flex-end;
    padding-bottom: 1.5px;
}


/* Date inside date header in message list */
.cometchat-message-list__date-header .cometchat-date,
.cometchat-message-list__bubble-date-header .cometchat-date  {
    color: var(--cometchat-text-color-primary);
    font: var(--cometchat-font-caption1-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

/* Date inside message bubble status info view */
.cometchat-message-bubble__status-info-view .cometchat-date {
    padding: 0px;
}

/* Bubble layout in message list */
.cometchat-messagelist__bubble {
    padding: 0px 0px 0px 15px;
    overflow: hidden;
    display: flex;
    margin-right: 8px;
    box-sizing: border-box;
    width: 100%;
}

/* Main message list container */
.cometchat-message-list {
    height: 100%;
    width: 100%;
    flex: 1 1 0;
    order: 2;
    position: relative;
    display: flex;
    border-radius: inherit;
    background: var(--cometchat-background-color-02);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
}

/* Header inside message list */
.cometchat-message-list__header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
}

/* Body inside message list */
.cometchat-message-list__body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* Message indicator inside message list */
.cometchat-message-list__message-indicator {
    margin-bottom: 8px;
    display: flex;
    width: 100;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cometchat-message-list__message-indicator>.cometchat {
    width: fit-content;
}

/* Button inside message indicator */
.cometchat-message-list__message-indicator .cometchat-button {
    background: var(--cometchat-static-black);
    height: fit-content;
    width: fit-content;
    display: flex;
    padding: 8px var(--cometchat-padding-2, 8px);
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--cometchat-radius-max, 4px);
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Text inside message indicator button */
.cometchat-message-list__message-indicator .cometchat-button__text {
    color: var(--cometchat-static-white);
    text-align: center;
    font: var(--cometchat-font-caption1-regular);
}

/* Icon inside message indicator button */
.cometchat-message-list__message-indicator .cometchat-button__icon {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-static-white);
}

/* Footer inside message list */
.cometchat-message-list__footer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
}

/* Leading view inside message bubble */
.cometchat-message-bubble__leading-view {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
}

/* Avatar inside leading view of message bubble */
.cometchat-message-bubble__leading-view .cometchat-avatar {
    width: 32px;
    height: 32px;
}

/* Header view inside message bubble */
.cometchat-message-bubble__header-view {
    color: var(--cometchat-text-color-highlight);
    font: var(--cometchat-font-caption1-medium);
    margin-bottom: var(--cometchat-margin-1);
}

/* Receipt icon inside message list */
.cometchat-message-list__receipt {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Status view for "sending" receipt inside message bubble */
.cometchat-message-bubble__status-info-view-receipts-wait .cometchat-message-list__receipt {
    -webkit-mask: url("../../assets/status_sending.svg") center center no-repeat;
    -webkit-mask-size: contain;
    background: var(--cometchat-static-white);
}

/* Status view for "sent" receipt inside message bubble */
.cometchat-message-bubble__status-info-view-receipts-sent .cometchat-message-list__receipt {
    -webkit-mask: url("../../assets/status_sent.svg") center center no-repeat;
    -webkit-mask-size: contain;
    background: var(--cometchat-static-white);

}

/* Status view for "delivered" receipt inside message bubble */
.cometchat-message-bubble__status-info-view-receipts-delivered .cometchat-message-list__receipt {
    -webkit-mask: url("../../assets/status_delivered.svg") center center no-repeat;
    -webkit-mask-size: contain;
    background: var(--cometchat-static-white);

}

/* Status view for "read" receipt inside message bubble */
.cometchat-message-bubble__status-info-view-receipts-read .cometchat-message-list__receipt {
    -webkit-mask: url("../../assets/status_read.svg") center center no-repeat;
    -webkit-mask-size: contain;
    background: var(--cometchat-message-seen-color,#56E8A7);
    

}

/* Error receipt status inside message bubble */
.cometchat-message-bubble__status-info-view-receipts-error .cometchat-message-list__receipt {
    -webkit-mask: url("../../assets/error_info.svg") center center no-repeat;
    background: var(--cometchat-static-white);
    height: 12px;
    width: 12px;
}

/* Error receipt status view inside message bubble */
.cometchat-message-bubble__status-info-view-receipts-error {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--cometchat-error-color);
    border-radius: var(--cometchat-radius-max);
}

/* shimmer */
.cometchat-message-list__shimmer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--cometchat-background-color-01);
    padding: 0px var(--cometchat-padding-4, 16px) var(--cometchat-padding-4, 16px) var(--cometchat-padding-4, 16px);

}

.cometchat-message-list__shimmer-body {
    display: flex;
    width: 286px;
    align-items: center;
    position: relative;
    border-radius: var(--cometchat-radius-2, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-2);
}

.cometchat-message-list__shimmer-leading-view {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 8px);
    background: var(--cometchat-shimmer-gradient-color);
    background-size: 200% 100%;
    animation: shimmer .8s infinite;
    margin-right: var(--cometchat-margin-2);

}

.cometchat-message-list__shimmer-header {
    display: flex;
    width: 50px;
    height: 22px;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: var(--cometchat-radius-2, 8px);
    margin-top: 10px;


}

.cometchat-message-list__shimmer-header .cometchat-message-list__shimmer-item {
    height: 22px;
}

.cometchat-message-list__shimmer-item-header {
    display: flex;
    width: 120px;
    height: 12px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-shimmer-gradient-color);
    background-size: 200% 100%;
    animation: shimmer .8s infinite;
    border-radius: var(--cometchat-radius-2, 8px);
}

.cometchat-message-list__shimmer-item {
    width: 100%;
    height: 53px;
    background: var(--cometchat-shimmer-gradient-color);
    background-size: 200% 100%;
    animation: shimmer .8s infinite;
    border-radius: var(--cometchat-radius-2, 8px);
}

/* call status  */
.cometchat-message-bubble .cometchat-message-bubble__video-call,
.cometchat-message-bubble .cometchat-message-bubble__audio-call {
    background: transparent;
    padding: 0px;
}

.cometchat-message-bubble .cometchat-message-bubble__video-call .cometchat-action-bubble__icon,
.cometchat-message-bubble .cometchat-message-bubble__audio-call .cometchat-action-bubble__icon {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.cometchat-message-bubble__video-call .cometchat-action-bubble,
.cometchat-message-bubble__audio-call .cometchat-action-bubble {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    border: 1px solid var(--cometchat-border-color-default);
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__missed-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/missed_video_call_no_fill.svg");
    -webkit-mask-size: contain;
    background: var(--cometchat-error-color);
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__ended-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/call_end_no_fill.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__outgoing-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/outgoing_video_no_fill.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__incoming-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/incoming_video_no_fill.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__busy-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/video_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__answered-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/video_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__unanswered-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/video_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__cancelled-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/video_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__video-call .cometchat-message-bubble__rejected-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/video_call_button.svg");
    -webkit-mask-size: contain;
}


.cometchat-message-bubble__audio-call .cometchat-message-bubble__missed-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/phone_missed_no_fill.svg");
    -webkit-mask-size: contain;
    background: var(--cometchat-error-color);

}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__ended-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/call_end_no_fill.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__outgoing-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/phone_outgoing_no_fill.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__incoming-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/phone_incoming_no_fill.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__busy-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/audio_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__answered-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/audio_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__unanswered-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/audio_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__cancelled-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/audio_call_button.svg");
    -webkit-mask-size: contain;
}

.cometchat-message-bubble__audio-call .cometchat-message-bubble__rejected-call .cometchat-action-bubble__icon {
    -webkit-mask: url("../../assets/audio_call_button.svg");
    -webkit-mask-size: contain;
}


@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: 0% 200%;
    }


}
/**
 * Adding margin on first message to avoid overlap for message and date header.
 */
.cometchat-message-list__body .cometchat-list__body .cometchat-list__item-wrapper:nth-of-type(2) {
    margin-top: 30px;
  }

 /**
 * Setting transparent background and gap for sticker message bubble
 */
.cometchat-message-bubble__body-wrapper .cometchat-message-bubble__sticker-message {
    background-color: transparent;
    gap: var(--cometchat-padding-2, 8px);
}

/**
 * Removing cursor from sticker messages
 */
.cometchat-message-bubble__sticker-message .cometchat-image-bubble {
    cursor: default;
    background-color: transparent;
}

/**
 * Adding semi-transparent background to status info view in sticker messages
 */
.cometchat-message-bubble__sticker-message .cometchat-message-bubble__status-info-view {
    background: rgba(20, 20, 20, 0.60);
}

/**
 * Setting text color for date information in the status info view
 */
.cometchat-message-bubble__sticker-message .cometchat-message-bubble__status-info-view .cometchat-date {
    color: var(--cometchat-static-white, #F9F8FD);
}

/**
 * Styling for the message information popup wrapper to create a centered overlay
 */
.cometchat-message-information__popup-wrapper {
    display: flex;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}