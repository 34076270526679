/* Wrapper for the message bubble */
.cometchat-message-bubble__wrapper {
    display: flex;
    position: relative;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: flex-start;
    gap: var(--cometchat-padding-2, 8px);
    flex: 1 0 0;
}
.cometchat-message-bubble .cometchat-reactions{
    width: 100%;
    margin-top: -4px;
}
.cometchat-message-bubble-outgoing .cometchat-reactions{
    justify-content: flex-end;
}
.cometchat-message-bubble-incoming .cometchat-reactions{
    justify-content: flex-start;
}
/* Avatar styles inside the message bubble */
.cometchat-message-bubble__leading-view {
    position: relative;
    border-radius: inherit;
    display: flex;
    width: fit-content;
    align-self: flex-start;
}

/* Alignment styles for the message bubble */
.cometchat-message-bubble {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
}


/* Alignment for receiver's messages */
.cometchat-message-bubble-incoming {
    justify-content: flex-start;
    align-items: flex-start;
}
.cometchat-message-bubble__text-message .cometchat-message-bubble__body-content-view{
    width: 100%;
}
.cometchat-message-bubble  .cometchat-text-bubble__body-text{
text-align: left;
}

/* Alignment for sender's messages */
.cometchat-message-bubble-outgoing {
    justify-content: flex-end;
    align-items: flex-end;
}

/* Alignment for action  messages */
.cometchat-message-bubble-action {
    justify-content: center;
    align-items: center;
}

/* Background for incoming message bubble body */
.cometchat-message-bubble-incoming .cometchat-message-bubble__body {
    background: var(--cometchat-neutral-color-300);
}

/* Background for outgoing message bubble body */
.cometchat-message-bubble-outgoing .cometchat-message-bubble__body {
    background: var(--cometchat-primary-color);
}

/* Body of the message bubble */
.cometchat-message-bubble__body {
    display: flex;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-1, 4px) 0px var(--cometchat-padding-1, 4px);
    flex-direction: column;
    align-items: flex-end;
    border-radius: var(--cometchat-radius-3, 12px);
}

/* Wrapper around the body of the message bubble */
.cometchat-message-bubble__body-wrapper {
    order: 2;
}

/* Title/header styles */
.cometchat-message-bubble__header-view {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Styles for message options */
.cometchat-message-bubble__options {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px var(--cometchat-padding-2, 8px);
}

/* Options for incoming message bubble */
.cometchat-message-bubble-incoming .cometchat-message-bubble__options {
    order: 3;
}

/* Options for outgoing message bubble */
.cometchat-message-bubble-outgoing .cometchat-message-bubble__options {
    order: 1;
}