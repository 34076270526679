.cometchat-message-information {
    width: 420px;
    height: fit-content;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-5, 20px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    overflow: hidden;
}

.cometchat-message-information__header {
    display: flex;
    height: 64px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    align-self: stretch;
    border-bottom: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    border-left: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-message-information__header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0px;
    flex: 1 0 0;
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading3-bold, 700 20px Roboto);
}

.cometchat-message-information__header-close {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cometchat-message-information__header-close .cometchat-button {
    height: 24px;
    width: 24px;
    padding: 0px;
    gap: 0px;
    background: transparent;
}

.cometchat-message-information__header-close .cometchat-button:hover {
    background: transparent;
}

.cometchat-message-information__header-close .cometchat-button:active {
    background: transparent;
}

.cometchat-message-information__header-close .cometchat-button__icon {
    background: var(--cometchat-icon-color-primary, #141414);
}

.cometchat-message-information__header-close .cometchat-button__icon:hover {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-message-information__message {
    display: flex;
    padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-4, 16px);
    align-items: flex-start;
    gap: 0px;
    width: 100%;
    max-height: 300px;
    overflow: hidden auto;
    background: var(--cometchat-background-color-02, #FAFAFA);
    border-bottom: 1px solid var(--cometchat-border-color-light);
}

.cometchat-message-information__message::-webkit-scrollbar,
.cometchat-message-information__message::-webkit-scrollbar-thumb {
    display: none;
}


.cometchat-message-information__message .cometchat-message-bubble__wrapper {
    padding: 0px;
    width: 100%;
}

.cometchat-message-information__receipts {
    display: flex;
    width: 100%;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    max-height: 300px;
    overflow: hidden auto;
}

.cometchat-message-information__receipts .cometchat-list-item__body-subtitle {
    flex-direction: column;
    gap: 0px;
}

.cometchat-message-information__receipts::-webkit-scrollbar,
.cometchat-message-information__receipts::-webkit-scrollbar-thumb {
    display: none;
}

.cometchat-message-information__receipts .cometchat-list-item__body {
    padding: 0px;
}

.cometchat-message-information__receipts .cometchat-list-item:hover {
    background: transparent;
}

.cometchat-message-information__receipts-subtitle {
    display: flex;
    padding: 0px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-1, 4px);
    flex: 1 0 0;
}

.cometchat-message-information__receipts-subtitle-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);

}

.cometchat-message-information__receipts .cometchat-list-item {
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    align-items: flex-start;
}

.cometchat-message-information__receipts .cometchat-list-item__trailing-view {
    display: none;
}

.cometchat-message-information__receipts .cometchat-list-item__leading-view,
.cometchat-message-information__receipts .cometchat-avatar,
.cometchat-message-information__receipts .cometchat-avatar__image {
    height: 40px;
    width: 40px;
}

.cometchat-message-information__shimmer {
    width: 100%;
    height: 100%;
    background: var(--cometchat-background-color-01, #FFF);

}

.cometchat-message-information__shimmer-item {
    width: 100%;
    height: 72px;
    display: flex;
    width: 100%;
    min-width: 240px;
    max-width: 1440px;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: 12px;
}

.cometchat-message-information__shimmer-item-avatar {
    width: 48px;
    height: 48px;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-shimmer-gradient-color, linear-gradient(90deg, #e0e0e0 0%, #eee 100%));
    animation: shimmerAnimation 5s infinite linear;
}

.cometchat-message-information__shimmer-item-title {
    background: var(--cometchat-shimmer-gradient-color);
    border-radius: var(--cometchat-radius-2, 8px);
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    animation: shimmerAnimation 5s infinite linear;
}

@keyframes shimmerAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.cometchat-message-information__receipts-subtite-text,
.cometchat-message-information__receipts-subtitle .cometchat-date,
.cometchat-message-information__receipts-subtite-text .cometchat-date {
    padding: 0px;
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.cometchat-message-information__receipts-empty,
.cometchat-message-information__error-state {
    display: flex;
    height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.cometchat-message-information .cometchat-list__header {
    border-bottom: none;
}