@import url('./CometChatConversationStarter.css');
@import url('./CometChatSmartReplies.css');
@import url('./CometChatConversationSummary.css');
@import url('./CometChatActionSheet.css');
@import url('./CometChatAudioBubble.css');
@import url('./CometChatAvatar.css');
@import url('./CometChatButton.css');
@import url('./CometChatCallBubble.css');
@import url('./CometChatChangeScope.css');
@import url('./CometChatCheckbox.css');
@import url('./CometChatConfirmDialog.css');
@import url('./CometChatContextMenu.css');
@import url('./CometChatDate.css');
@import url('./CometChatDeleteBubble.css');
@import url('./CometChatDocumentBubble.css');
@import url('./CometChatDropDown.css');
@import url('./CometChatEditPreview.css');
@import url('./CometChatEmojiKeyboard.css');
@import url('./CometChatFileBubble.css');
@import url('./CometChatFullScreenViewer.css');
@import url('./CometChatImageBubble.css');
@import url('./CometChatList.css');
@import url('./CometChatListItem.css');
@import url('./CometChatMediaRecorder.css');
@import url('./CometChatMessageBubble.css');
@import url('./CometChatPopover.css');
@import url('./CometChatQuickView.css');
@import url('./CometChatRadioButton.css');
@import url('./CometChatSearchBar.css');
@import url('./CometChatSingleSelect.css');
@import url('./CometChatTextBubble.css');
@import url('./CometChatVideoBubble.css');
@import url('./CreatePolls.css');
@import url('./LinkPreview.css');
@import url('./MessageTranslationBubble.css');
@import url('./PollsBubble.css');
@import url('./StickersKeyboard.css');
@import url('./CometChatGroupMembers.css');
@import url('./CometChatCallLogs.css');
@import url('./CometChatConversations.css');
@import url('./CometChatUsers.css');
@import url('./CometChatGroups.css');
@import url('./CometChatMessageHeader.css');
@import url('./CometChatMessageComposer.css');
@import url('./CometChatMessageList.css');
@import url('./CometChatOutgoingCall.css');
@import url('./CometChatActionBubble.css');
@import url('./CometChatReactionInfo.css');
@import url('./CometChatReactionList.css');
@import url('./CometChatReactions.css');
@import url('./CometChatCallButtons.css');
@import url('./CometChatIncomingCall.css');
@import url('./CometChatMessageInformation.css');
@import url('./CometChatOngoingCall.css');
@import url('./CometChatThreadedMessagePreview.css');
@import url('./CometChatToast.css');




.cometchat-mentions > span{
    color: var(--cometchat-text-color-highlight);
font:var(--cometchat-font-body-regular);
}
.cometchat-mentions{
    display: inline-block;
    width: fit-content;
}
.cometchat-mentions-outgoing,.cometchat-mentions-incoming{
    border-radius: var(--cometchat-radius, 2px);
    display: inline-flex;
    padding: 0px var(--cometchat-padding, 2px);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cometchat-mentions-outgoing.cometchat-mentions-you{
    background: rgba(255, 171, 0, 0.20);
}
.cometchat-mentions-outgoing.cometchat-mentions-other{
    background: rgba(249, 248, 253, 0.20);
}
.cometchat-mentions-incoming.cometchat-mentions-you{
    background: rgba(255, 171, 0, 0.20);
}
.cometchat-mentions-incoming.cometchat-mentions-other{
    background: rgba(104, 82, 214, 0.20);
}
.cometchat-mentions-outgoing.cometchat-mentions-you > span{
    color: var(--cometchat-warning-color);

}
.cometchat-mentions-outgoing.cometchat-mentions-other > span{
    color: var(--cometchat-static-white);

}
.cometchat-mentions-incoming.cometchat-mentions-you > span{
    color: var(--cometchat-warning-color);

}
.cometchat-mentions-incoming.cometchat-mentions-other > span{
    color: var(--cometchat-text-color-highlight);

}
.cometchat-text-bubble-incoming .cometchat-url{
    color: var(--cometchat-info-color);
}
.cometchat-text-bubble .cometchat-url{
    text-decoration: underline;
}