.cometchat-polls-bubble {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--cometchat-padding-2, 8px);
  border-radius: var(--cometchat-radius-3, 12px);
  gap: var(--cometchat-padding-4, 16px);
  width: 240px;
  max-width: 240px;
}

.cometchat-polls-bubble-incoming {
  background: var(--cometchat-neutral-color-300, #E8E8E8);
}

.cometchat-polls-bubble-outgoing {
  background: var(--cometchat-primary-color, #6852D6);
}

.cometchat-polls-bubble .cometchat-avatar {
  padding: 0px;
  width: 20px;
  height: 20px;
}


.cometchat-polls-bubble .cometchat-avatar img {
  width: 20px;
  height: 20px;
  border-radius: var(--cometchat-radius-max, 1000px);
}

.cometchat-polls-bubble-incoming .cometchat-avatar img {
  border: 1px solid var(--cometchat-neutral-color-300, #E8E8E8);
}

.cometchat-polls-bubble-outgoing .cometchat-avatar img {
  border: 1px solid var(--cometchat-primary-color, #6852D6);
}

.cometchat-polls-bubble .cometchat-avatar .cometchat-avatar__text {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-max, 1000px);
  background: var(--cometchat-extended-primary-color-600, #AA9EE8);
  font: var(--cometchat-font-caption2-regular);
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-polls-bubble__question {
  font: var(--cometchat-font-heading4-bold);
  font-style: normal;
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.cometchat-polls-bubble-incoming .cometchat-polls-bubble__question {
  color: var(--cometchat-neutral-color-900, #141414);
}

.cometchat-polls-bubble-outgoing .cometchat-polls-bubble__question {
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-polls-bubble__options {
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  padding-bottom: var(--cometchat-padding-3, 12px);
  gap: var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.cometchat-polls-bubble__options>li {
  display: flex;
  gap: var(--cometchat-padding-2, 8px);
  width: 100%;
  position: relative;
}


.cometchat-polls-bubble__option-item {
  width: 100%;
  justify-content: space-between;
  padding: 0px;
}
.cometchat-poll-bubble__option-item-leading-view {
  cursor: pointer;
}

.cometchat-poll-bubble__option-item-leading-view-radio-button {
  width: 20px;
  height: 20px;
  border-radius: var(--cometchat-radius-max, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-leading-view-radio-button {
  border: 1.5px solid var(--cometchat-icon-color-secondary, #A1A1A1);
}

.cometchat-polls-bubble-outgoing .cometchat-poll-bubble__option-item-leading-view-radio-button {
  border: 1.5px solid var(--cometchat-static-white, #FFFFFF);
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-leading-view-radio-button.checked {
  background: var(--cometchat-icon-color-highlight, #6852D6);
  color: var(--cometchat-background-color-01, #FFF);
  border: var(--cometchat-icon-color-highlight, #6852D6);
}

.cometchat-polls-bubble-outgoing .cometchat-poll-bubble__option-item-leading-view-radio-button.checked {
  background-color: var(--cometchat-static-white, #FFF);
  color: #6852D6;
  border: 1px solid var(--cometchat-icon-color-highlight, #6852D6);
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-leading-view-radio-button.checked::after,
.cometchat-poll-bubble__option-item-leading-view-radio-button.checked::after {
  content: '✓';
  font-size: 12px;
  font-weight: 900;
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-leading-view-radio-button.checked::after {
  color: var(--cometchat-background-color-01, #FFFFFF);
}

.cometchat-poll-bubble__option-item-leading-view-radio-button.checked::after {
  color: var(--cometchat-primary-color, #6852D6);
}


.cometchat-poll-bubble__option-item-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-spacing-1, 4px);
  overflow: hidden;
}

.cometchat-poll-bubble__option-item-body-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cometchat-poll-bubble__option-item-body-content-title {
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  text-align: left;
  word-wrap: break-word;
  width: 100%;
  overflow: hidden;
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-body-content-title {
  color: var(--cometchat-neutral-color-900, #141414);
}

.cometchat-polls-bubble-outgoing .cometchat-poll-bubble__option-item-body-content-title {
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-poll-bubble__option-item-body-content-tail {
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  position: relative;
  height: 20px;
  width: fit-content;
  gap: var(--cometchat-padding-1);
}

.cometchat-poll-bubble__option-item-body-content-tail-avatar {
  height: 20px;
  width: 20px;
  margin-right: -8px;
  z-index: 2;
}

.cometchat-poll-bubble__option-item-body-content-tail-avatar.last {
  margin-right: 0;
  z-index: 0;
}

.cometchat-poll-bubble__option-item-body-content-tail-count {
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-body-content-tail-count {
  color: var(--cometchat-neutral-color-900, #141414);
}

.cometchat-polls-bubble-outgoing .cometchat-poll-bubble__option-item-body-content-tail-count {
  color: var(--cometchat-static-white, #FFF);
}

.cometchat-poll-bubble__option-item-body-progress {
  margin-top: 3px;
  width: 100%;
  height: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-body-progress {
  background: var(--cometchat-neutral-color-400, #DCDCDC);
}

.cometchat-polls-bubble-outgoing .cometchat-poll-bubble__option-item-body-progress {
  background: var(--cometchat-extended-primary-color-700, #8978DF);
}

.cometchat-poll-bubble__option-item-body-progress-background {
  height: 8px;
  width: 20px;
  border-radius: var(--cometchat-radius-4, 16px);
}

.cometchat-polls-bubble-incoming .cometchat-poll-bubble__option-item-body-progress-background {
  background: var(--cometchat-primary-color, #6852D6);
}

.cometchat-polls-bubble-outgoing .cometchat-poll-bubble__option-item-body-progress-background {
  background: var(--cometchat-static-white, #FFF);
}


.cometchat-polls-bubble-incoming .cometchat-radiobutton__selected {
  border: 1.5px solid var(--cometchat-primary-color, #6852D6);
}

.cometchat-polls-bubble-outgoing .cometchat-radiobutton__selected {
  border: 1.5px solid var(--cometchat-static-white, #FFF);
}

.cometchat-polls-bubble-outgoing .cometchat-radiobutton input[type="radio"]:checked+.cometchat-radiobutton__selected {
  border: 1.5px solid var(--cometchat-static-white, #FFF);
}

.cometchat-polls-bubble-outgoing .cometchat-radiobutton__selected::after {
  background-color: var(--cometchat-static-white, #FFF);
}